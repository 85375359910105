import { ModelsApplicationRoleResponse } from './generated/model';

export const createApplicationRoles = (): (Omit<ModelsApplicationRoleResponse, 'id'> & { id: string })[] => [
  {
    id: '8b22c972-f21d-46e2-841e-ed6ddaf04965',
    marketRoles: [],
    name: 'mpr:admin:read',
    users: [
      {
        id: '8534865f-f30d-4119-a374-203ad0f8dcb6',
        userName: 'daimy.van.veggel',
        givenName: 'Daimy',
        familyName: 'van Veggel',
        email: 'daimy.van.veggel@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [],
  },
  {
    id: '0e2d599f-b87e-4f0d-9fcb-712777455d87',
    marketRoles: [],
    name: 'mpr:admin:write',
    users: [
      {
        id: '8534865f-f30d-4119-a374-203ad0f8dcb6',
        userName: 'daimy.van.veggel',
        givenName: 'Daimy',
        familyName: 'van Veggel',
        email: 'daimy.van.veggel@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [],
  },
  {
    id: 'd6fd297b-db32-4015-b2b6-2bcd6e14ac88',
    marketRoles: [],
    name: 'getp4:write',
    users: [
      {
        id: '8534865f-f30d-4119-a374-203ad0f8dcb6',
        userName: 'daimy.van.veggel',
        givenName: 'Daimy',
        familyName: 'van Veggel',
        email: 'daimy.van.veggel@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [
      {
        id: 'acea9173-f3a0-413c-a860-a013fa946073',
        name: '2810167453',
        description: 'my-test-account-2',
      },
    ],
  },
  {
    id: 'dedd8c45-887b-402f-aa46-501262520ccd',
    marketRoles: [],
    name: 'mpr:admin:config',
    users: [
      {
        id: '8534865f-f30d-4119-a374-203ad0f8dcb6',
        userName: 'daimy.van.veggel',
        givenName: 'Daimy',
        familyName: 'van Veggel',
        email: 'daimy.van.veggel@edsn.nl',
        active: true,
      },
      {
        id: 'e669e527-cbaa-4bc3-b5b7-1c3b9db915da',
        userName: 'stefano.grimberg',
        givenName: 'Stefano',
        familyName: 'Grimberg',
        email: 'stefano.grimberg@edsn.nl',
        active: false,
      },
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
      {
        id: 'bb37dc6a-0788-4123-a6de-7fa171c5177c',
        userName: 'SFTP-1',
        givenName: 'SFTP-1',
        familyName: 'SFTP-1',
        email: 'do-not-reply@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [
      {
        id: '78e9d815-aaf2-4714-a501-5ecb493f0fd0',
        name: 'Antonie',
        description: 'Antonie',
      },
      {
        id: 'acea9173-f3a0-413c-a860-a013fa946073',
        name: '2810167453',
        description: 'my-test-account-2',
      },
    ],
  },
  {
    id: 'de8c6043-cb3b-4b1a-bfa2-c9c6300bec3a',
    marketRoles: [],
    name: 'getp4:read',
    users: [],
    serviceAccounts: [
      {
        id: '42ea001c-5385-4c8f-af96-4d2387ce5107',
        name: '2599104312',
        description: 'demo-account',
      },
      {
        id: 'acea9173-f3a0-413c-a860-a013fa946073',
        name: '2810167453',
        description: 'my-test-account-2',
      },
    ],
  },
  {
    id: '9fb8d8fe-295e-4724-8829-eff7ce8371f5',
    marketRoles: [],
    name: 'test_role_abc',
    description: 'Test',
    users: [
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [
      {
        id: '78e9d815-aaf2-4714-a501-5ecb493f0fd0',
        name: 'Antonie',
        description: 'Antonie',
      },
    ],
  },
  {
    id: '060cf81d-936e-47b3-8b50-cbbc41e4dec6',
    marketRoles: [],
    name: 'cmp:application:write',
    description: 'Allows access to C-MP and the ability to modify data.',
    users: [],
    serviceAccounts: [
      {
        id: '554824cc-5b97-426e-9158-1ab83f19764d',
        name: '3439565919',
        description: 'my-test-account-2',
      },
    ],
  },
  {
    id: '6b34b0a4-59de-4c44-9500-7460f93b25b7',
    marketRoles: [],
    name: 'cmp:application:admin',
    description: 'Allows administrator access to C-MP.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '6cb35769-e3c3-4d17-9bc2-b2eae27bc6a4',
    marketRoles: [],
    name: 'cmp:application:read',
    description: 'Allows access to C-MP.',
    users: [
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
      {
        id: 'e669e527-cbaa-4bc3-b5b7-1c3b9db915da',
        userName: 'stefano.grimberg',
        givenName: 'Stefano',
        familyName: 'Grimberg',
        email: 'stefano.grimberg@edsn.nl',
        active: false,
      },
    ],
    serviceAccounts: [],
  },
  {
    id: '44980820-b054-4966-86f6-a6ff6d5afe43',
    marketRoles: [],
    name: 'filegateway:ui:write',
    description: 'Write-access to the File Gateway UI.',
    users: [
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [
      {
        id: '42ea001c-5385-4c8f-af96-4d2387ce5107',
        name: '2599104312',
        description: 'demo-account',
      },
      {
        id: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
        name: 'my-test-account',
        description: 'my-test-account',
      },
    ],
  },
  {
    id: '5e26c050-6589-4dab-aafc-8131c605f790',
    marketRoles: [],
    name: 'regx:blah:write',
    description: 'something to describe',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'a0f4a0ad-7828-4d54-b75b-b30bd21aafe8',
    marketRoles: [],
    name: 'mar:read',
    description: 'mar read',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'd8e41e2a-7e3b-49d4-bb33-dd24e70d9736',
    marketRoles: [],
    name: 'mar:write',
    description: 'mar write',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '1628f9d6-758d-4764-a67f-764ccf0a18df',
    marketRoles: [],
    name: 'connection-register:admin:write',
    description: 'Een write rol dat is wat logischer.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'fa7428ba-9c2d-4861-8d24-2e0fac27b2c9',
    marketRoles: [],
    name: 'idm:application_role:read',
    description: 'Identity Management - Read Application Roles',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '9d3f0a10-ade6-43ee-88a8-12af88299c29',
    marketRoles: [],
    name: 'idm:application_role:write',
    description: 'Identity Management - Write Application Roles',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '07788d95-d7e3-43c0-9464-74973898440c',
    marketRoles: [],
    name: 'idm:user:read',
    description: 'Identity Management - Read Users',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '6d0840ab-ae64-4ccc-9ecf-9e16bd42e41b',
    marketRoles: [],
    name: 'idm:market_participant:read',
    description: 'Identity Management - Read Market Participants',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'f1c51255-c779-4f84-b989-2abdf9b6c1fa',
    marketRoles: [],
    name: 'idm:market_participant:write',
    description: 'Identity Management - Write Market Participants',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '3fd96022-4451-4b49-9c27-d7cfc3bee9a9',
    marketRoles: [],
    name: 'idm:service_account:read',
    description: 'Identity Management - Read Service Accounts',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'b69ab727-3ae4-4db9-920e-4c8b9c77244c',
    marketRoles: [],
    name: 'idm:service_account:write',
    description: 'Identity Management - Write Service Accounts',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'f19db48a-a85c-42a6-9ba5-702dd22accb5',
    marketRoles: [],
    name: 'idm:user:write',
    description: 'Identity Management - Write Users',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '88c005b1-cd95-428d-9e2e-06c52e03122f',
    marketRoles: [],
    name: 'filegateway:flexibiliteitsmanagement_conformiteitenlijst:admin',
    description: 'Ability to admin transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '6113ee31-8299-4c56-bb4a-dd9864c025cf',
    marketRoles: [],
    name: 'filegateway:flexibiliteitsmanagement_conformiteitenlijst:write',
    description: 'Ability to write transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '19bc9a51-db33-4481-b9f4-5a891aeda4e3',
    marketRoles: [],
    name: 'filegateway:flexibiliteitsmanagement_conformiteitenlijst:read',
    description: 'Ability to read transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'ca20d2aa-1790-4acf-8c46-fca9a3253e3a',
    marketRoles: [],
    name: 'filegateway:transfer:write',
    description: 'Ability to create transfers in the File Gateway.',
    users: [
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [],
  },
  {
    id: 'afb27a41-3eb8-4d38-aab2-0f6be22d2d2d',
    marketRoles: [],
    name: 'filegateway:centralerapportage_rnb-datadienst-ar:write',
    description: 'Ability to write transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'c4c662c2-6bc7-4dd3-9807-ce844ece5e00',
    marketRoles: [],
    name: 'mijn-aansluiting:admin:read',
    description: 'de rol waarmee de zoekfunctionaliteit van Mijn Aansluiting toegestaan wordt',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '1140056d-3699-4126-8dec-64070f17b662',
    marketRoles: [],
    name: 'filegateway:ui:read',
    description: 'Read-access to the File Gateway UI.',
    users: [
      {
        id: '48f04657-ad47-41e1-966e-65fe9f96a888',
        userName: 'jisk.reijn',
        givenName: 'Jisk',
        familyName: 'Reijn',
        email: 'jisk.reijn@edsn.nl',
        active: true,
      },
    ],
    serviceAccounts: [],
  },
  {
    id: '3d78cf66-16c2-4299-944f-ffde9c63de35',
    marketRoles: [],
    name: 'filegateway:centralerapportage_rnb-rg02:write',
    description: 'Ability to write transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'fd4c0dd7-c303-4994-901a-6f851865bd2e',
    marketRoles: [],
    name: 'filegateway:centralerapportage_grootverbruik-telemetrie:write',
    description: 'Ability to write transfers in the FileGateway.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'b0a0ca7c-8317-406a-ad8c-e8362aab1399',
    marketRoles: [],
    name: 'connection-register:admin:read',
    description: 'Een read rol dat is vreemd.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'e85d49db-a721-45f3-8d27-8567ce5f7e9c',
    marketRoles: [],
    name: 'edsn:greeting:write',
    description: 'some description that describes the application role.',
    users: [],
    serviceAccounts: [],
  },
  {
    id: '38ea1688-afbc-4aa2-af3a-7fed44180352',
    marketRoles: [],
    name: 'tableau:fundamentum-demo:read',
    description: 'Test group for Fundamentum',
    users: [],
    serviceAccounts: [],
  },
  {
    id: 'e12aa6fe-4c16-40aa-89b7-3dc79c68a08b',
    marketRoles: [],
    name: 'tableau:cia-demo:write',
    description: 'Test group for CIA',
    users: [],
    serviceAccounts: [],
  },
];
